@import "../theme";

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Barlow";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #4F4F4F;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-container .checkmark {
  position: absolute;
  top: 0%;
  left: 0%;
 // transform: translate(50%, -50%);
  height: 20px;
  width: 20px;
  border: 1.5px solid $lighter-text;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: $brand-secondary;
  border-color: $brand-secondary;
}

.checkbox-container:hover input ~ .purple {
  background-color:#4C3F76;
  border-color: #4C3F76;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $brand-secondary;
  border-color: $brand-secondary;
}

.checkbox-container input:checked ~ .purple {
  background-color: #4C3F76;
  border-color:#4C3F76;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark {
  &::after {
    left: 29%;
    top: 4%;
    width: 43%;
    height: 74%;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }
}
