@import "../theme.scss";


.layout--welcome {
  max-width: 1440px;
  margin: auto;

  .name {
    font-family: "GlacialIndifference-bold";
    font-size: 24px;
    line-height: 29px;
    color: $lighter-text;
  }

  .title {
    font-family: "GlacialIndifference-bold";
    font-size: 36px;
    line-height: 43px;
    color: $brand-secondary;
  }

  .sub-title {
    font-family: "Barlow";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: $black;
  }

  @media only screen and (max-width: 900px) {
    .add-car-container {
      text-align: left !important;
    }

    .company-details {
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .logo {
      height: 65px;
    }
  }
}