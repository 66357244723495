$slider-width-number: 800;
$slider-width: #{$slider-width-number}px;
$slider-height: 2px;
$background-slider: #4f4f4f;
$background-filled-slider-blue: #29c7e2;
$background-filled-slider-purple: #4c3f76;
$background-filled-slider-red: #f77a74;
$thumb-width: 24px;
$thumb-height: 24px;
$thumb-radius: 100%;
$thumb-background: #fff;
$thumb-background-purple: #4c3f76;
$thumb-border: 1px solid #bdbdbd;
$thumb-border-purple: 1px solid #4c3f76;
$shadow-size: -11px;
$fit-thumb-in-slider: -11px;

@function makelongshadow($color, $size) {
  $val: 5px 0 0 $size $color;

  @for $i from 6 through $slider-width-number {
    $val: #{$val}, #{$i}px 0 0 $size #{$color};
  }

  @return $val;
}

.slider {
  align-items: center;
  appearance: none;
  background: none;
  cursor: pointer;
  display: flex;
  min-height: 32px;
  overflow: hidden;
  width: 100%;
  border-radius: 19px;
  &:focus {
    box-shadow: none;
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    background: $background-filled-slider-blue;
    content: "";
    height: $slider-height;
    //pointer-events: none;
  }

  &::-webkit-slider-thumb {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    appearance: none;
    background-color: $thumb-background;
    border-radius: $thumb-radius;
    box-shadow: makelongshadow($background-slider, $shadow-size);
    margin-top: $fit-thumb-in-slider;
    border: $thumb-border;
  }

  &::-moz-range-track {
    width: $slider-width;
    height: $slider-height;
    background: $background-slider;
  }

  &::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: $thumb-background;
    border-radius: $thumb-radius;
    border: $thumb-border;
    position: relative;
  }

  &::-moz-range-progress {
    height: $slider-height;
    background: $background-filled-slider-blue;
    border: 0;
    margin-top: 0;
  }

  &::-ms-track {
    background: transparent;
    border: 0;
    border-color: transparent;
    border-radius: 0;
    border-width: 0;
    color: transparent;
    height: $slider-height;
    margin-top: 10px;
    width: $slider-width;
  }

  &::-ms-thumb {
    width: 24px;
    height: 24px;
    background: $thumb-background;
    border-radius: $thumb-radius;
    border: $thumb-border;
  }

  &::-ms-fill-lower {
    background: $background-filled-slider-blue;
    border-radius: 0;
  }

  &::-ms-fill-upper {
    background: $background-slider;
    border-radius: 0;
  }

  &::-ms-tooltip {
    display: none;
  }
}

.slider.purple-slider { 
  &::-webkit-slider-runnable-track {
    background: $background-filled-slider-red;
  }
  &::-moz-range-progress {
    background: $background-filled-slider-red;
  }
  &::-ms-fill-lower {
    background: $background-filled-slider-red;
  }
}
