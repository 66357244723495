@import "../theme.scss";

.layout--form {
  .data-field {
    width: 225px;
  }

  .title {
    display: inline-flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    min-width: 150px;
  }

  .fuel-type-options {
    padding: 0;

    li {
      border: 1px solid $lighter-text;
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      width: 105px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $lighter-text;
      padding: 7px;
      margin-right: 21px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: $lighter-text;
        color: $white;
      }
    }
  }

  .document-upload-container {
    border: 1px dashed $line;
    box-sizing: border-box;
    text-align: center;
    padding: 30px;

    h6 {
      margin: 0;
      font-family: "Barlow";
      font-weight: 500;
      font-size: 14px;
    }

    img {
      height: 40px;
    }
  }

  .document-upload-container ul {
    margin: 20px 0px;
    padding: 0;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 30px;
    flex-wrap: wrap;

    li {
      width: 117px;
      height: 117px;
      min-width: 90px;
      background: $line;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      position: relative;
      padding: 0px 3px;
      margin: 10px;
    }

    .active {
      background-color: $brand-primary;
    }

    .delete {
      height: 20px;
      width: 20px;
      background-image: url("../../icons/icon-delete-black.png");
      position: absolute;
      top: -6px;
      right: -7px;
      background-position: center;
      background-repeat: no-repeat;
    }

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      opacity: 0;
    }

    li h6 {
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: 900px) {
    >div {
      flex-direction: column;
    }
  }
}