@import "../theme.scss";

.layout--header--section {
  position: sticky;
  background-color: #ffffff;
  width: 100%;
  height: 85px;
  left: 0px;
  top: 0px;
  z-index: 3;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  .inner-content {
    display: flex;
    //justify-content: space-between;
    height: 100%;
    max-width: 1440px;
    width: 100%;
  }

  .header-content {
    margin: 0 20px;
    width: 100%;
    justify-content: flex-end;
    display: flex;
  }

  .logo {
    cursor: pointer;
    height: 100%;
    margin-left: 20px;
  }

  .logout {
    display: flex;

    img {
      height: 23px;
      width: 23px;
      margin-left: 20px;
    }
  }

  .main-header-search {
    width: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/*menu*/
.header-content ul {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0;

  a {
    position: relative;
    text-decoration: none;
    cursor: pointer;
    text-decoration: none;
    cursor: pointer;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 14px;
    font-family: "GlacialIndifference";
    color: $lighter-text;
    width: 130px;

    &:hover {
      font-family: "GlacialIndifference-Bold";
    }

    &::after {
      content: "";
      position: absolute;
      display: none;
      position: absolute;
      background-color: $brand-primary;
      height: 5px;
      width: 80px;
      bottom: -1px;
      transform: translate(-50%, 0);
      left: 50%;
    }

    :hover {
      text-decoration: none;
    }

    cursor: pointer;
  }

  .active {
    &::after {
      display: block;
    }

    color: $brand-primary;
    font-family: "GlacialIndifference-Bold";
  }
}

/*search*/
.header-search {
  width: 100%;
  height: 40px;
  background-color: $light-bg;
  border-radius: 5px;
  display: flex;
  padding-left: 10px;

  &:hover {
    .search-button {
      background-color: #4c3f76;
      box-shadow: -5px 0px 8px -5px #aaaaaa;
    }

    path {
      fill: #ffffff;
    }
  }
}

.header-search input {
  height: 100%;
  width: 100%;
  margin-left: 5px;
  background-color: $light-bg;
  color: $lighter-text;
  font-family: "Barlow";
  font-size: 13px !important;
  border: 0px;
  font-size: 14px;
}

.header-search input:focus~.search-button {
  background: #4c3f76;

  path {
    fill: #ffffff;
  }
}

.search-icon {
  height: 24px;
}

.search-button {
  background-color: #f2f2f2;
  border-radius: 0px 5px 5px 0px;
  padding: 0px 10px;
  border: 0;
  cursor: pointer;

  &:hover {
    box-shadow: -5px 0px 8px -5px #aaaaaa;
  }
}

.search-true .search-button {
  box-shadow: -5px 0px 8px -5px #aaaaaa;
  background-color: #4c3f76;

  path {
    fill: #ffffff;
  }
}

.mobile-menu {
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease-in-out;
  z-index: 2;

  .close-icon {
    height: 24px;
    margin: auto 0px;
  }

  .header {
    height: 85px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 0px 24px;
    border-bottom: 1px solid #c0c0c0;
  }

  .header h6 {
    font-family: "GlacialIndifference-Bold";
    font-size: 18px;
    color: $dark-text;
  }

  .content {
    padding: 0px 24px;

    .active li {
      color: $brand-primary;
      font-family: "GlacialIndifference-Bold";
    }
  }

  .content li {
    font-size: 16px;
    font-family: "GlacialIndifference";
    padding: 20px 20px 20px 0px;
    cursor: pointer;
    color: #4f4f4f;

    &:hover {
      font-family: "GlacialIndifference-Bold";
      color: $brand-primary;
    }
  }
}

//MEDIA QUERIES
@media only screen and (max-width: 1200px) {
  .layout--header--section {
    height: 61px;
    z-index: 2;

    .hide-mobile {
      display: none !important;
    }

    .hide-desktop {
      display: block;
    }

    .overlay {
      display: block;
      background-color: rgba(0, 0, 0, 0.2);
    }

    .logo {
      height: 60px;
      margin-left: 0px !important;
    }

    .top-right-menu {
      display: inline-flex !important;
    }

    .header-search {
      top: 0;
      padding: 0 10px;
    }

    .mobile-menu .header {
      height: 61px;
    }

    .main-header-search {
      width: calc(100vw - 159px) !important;
      margin: auto 14px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .logo {
    height: 90px !important;
    margin-left: 0px !important;
    padding: 0 !important;
  }

  .header-search {
    width: 255px;
  }
}

// @media only screen and (max-width: 900px) {
//   .main-header-search {
//     width: calc(100vw - 150px);
//     margin: auto 14px;
//   }
// }