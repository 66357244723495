@import "../theme";

button {
  &:focus {
    outline: none !important;
  }
}
.btn-primary-b {
  background: $brand-secondary !important;
  border-radius: 30px !important;
  position: relative;
  color: $white !important;
  font-size: 18px;
  border: 0;
  min-width: 111px;
  width: fit-content;
  text-align: left;
  font-family: "GlacialIndifference-Bold" !important;
  cursor: pointer;
  padding: 12px 29px;
  min-width: 120px !important;
}
.btn-primary-b:disabled {
  cursor: text;
}
@mixin btn-cancel {
  color: $brand-secondary !important;
  font-size: 18px;
  font-family: "GlacialIndifference-Bold";
  border: 0;
  text-align: center;
  background: none !important;
  height: 42px;
  margin: 0px 10px;
  background-color: transparent !important;
  &:focus {
    background-color: transparent !important;
    color: $brand-secondary !important;
    border: 0px !important;
    outline: none !important;
    box-shadow: none !important;
  }
  :disabled {
    color: #c0c0c0 !important;
    cursor: text;
  }
  &:hover:enabled {
    color: #29b7e2 !important;
  }
}

.btn-cancel {
  color: $lighter-text !important;
  font-size: 18px;
  font-family: "GlacialIndifference-Bold";
  border: 0;
  text-align: center;
  background: none !important;
  height: 42px;
  margin: 0px 10px;
  background-color: transparent !important;
  &:focus {
    background-color: transparent !important;
    color: $lighter-text !important;
    border: 0px !important;
    outline: none !important;
    box-shadow: none !important;
  }
}
.btn-cancel:disabled {
  cursor: text;
}
.btn-cancel:hover:enabled {
  color: $lighter-text !important;
}
.btn-transparent {
  background: transparent;
  border-radius: 8px;
  position: relative;
  color: #ffffff;
  font-size: 18px;
  border: 1px solid #ffffff;
  min-width: 111px;
  text-align: left;
  height: 42px;
  padding-left: 15px;
  font-family: "GlacialIndifference-Bold";
  margin: 0px 10px;
  &:after {
    height: 15px;
    width: 20px;
    position: absolute;
    content: "";
    background-image: url(../../icons/icon-btn-blue.svg);
    right: 22px;
    top: 50%;
    transform: translate(50%, -50%);
    background-position: center;
    background-repeat: no-repeat;
  }
  &:hover {
    // background-color: $brand-secondary;
    // color: #fff;
    &:after {
      background-image: url(../../icons/icon-btn-orange.svg);
    }
  }
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.2em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: "GlacialIndifference";
  display: flex;
  flex-direction: column;
  color: #232323;
  margin: 0px 0.4em;
}

.resp-sharing-button__icon svg {
  width: 3em;
  height: 3em;
  vertical-align: top;
}

.resp-pintrest {
  fill: #d32f2f;
}
.resp-email {
  fill: #64b5f6;
}
.resp-twitter {
  fill: #03a9f4;
}
.resp-facebook {
  fill: #4267b2;
}

// .resp-sharing-button--small svg {
//   margin: 0;
//   vertical-align: middle
// }

// /* Non solid icons get a stroke */
// .resp-sharing-button__icon {
//   stroke: #fff;
//   fill: none
// }

// /* Solid icons get a fill */
// .resp-sharing-button__icon--solid,
// .resp-sharing-button__icon--solidcircle {
//   fill: #fff;
//   stroke: none
// }

// .resp-sharing-button--twitter {
//   background-color: #55acee
// }

// .resp-sharing-button--twitter:hover {
//   background-color: #2795e9
// }

// .resp-sharing-button--pinterest {
//   background-color: #bd081c
// }

// .resp-sharing-button--pinterest:hover {
//   background-color: #8c0615
// }

// .resp-sharing-button--facebook {
//   background-color: #3b5998
// }

// .resp-sharing-button--facebook:hover {
//   background-color: #2d4373
// }

// .resp-sharing-button--tumblr {
//   background-color: #35465C
// }

// .resp-sharing-button--tumblr:hover {
//   background-color: #222d3c
// }

// .resp-sharing-button--reddit {
//   background-color: #5f99cf
// }

// .resp-sharing-button--reddit:hover {
//   background-color: #3a80c1
// }

// .resp-sharing-button--google {
//   background-color: #dd4b39
// }

// .resp-sharing-button--google:hover {
//   background-color: #c23321
// }

// .resp-sharing-button--linkedin {
//   background-color: #0077b5
// }

// .resp-sharing-button--linkedin:hover {
//   background-color: #046293
// }

// .resp-sharing-button--email {
//   background-color: #777
// }

// .resp-sharing-button--email:hover {
//   background-color: #5e5e5e
// }

// .resp-sharing-button--xing {
//   background-color: #1a7576
// }

// .resp-sharing-button--xing:hover {
//   background-color: #114c4c
// }

// .resp-sharing-button--whatsapp {
//   background-color: #25D366
// }

// .resp-sharing-button--whatsapp:hover {
//   background-color: #1da851
// }

// .resp-sharing-button--hackernews {
// background-color: #FF6600
// }
// .resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {   background-color: #FB6200 }

// .resp-sharing-button--vk {
//   background-color: #507299
// }

// .resp-sharing-button--vk:hover {
//   background-color: #43648c
// }

// .resp-sharing-button--facebook {
//   background-color: #3b5998;
//   border-color: #3b5998;
// }

// .resp-sharing-button--facebook:hover,
// .resp-sharing-button--facebook:active {
//   background-color: #2d4373;
//   border-color: #2d4373;
// }

// .resp-sharing-button--twitter {
//   background-color: #55acee;
//   border-color: #55acee;
// }

// .resp-sharing-button--twitter:hover,
// .resp-sharing-button--twitter:active {
//   background-color: #2795e9;
//   border-color: #2795e9;
// }

// .resp-sharing-button--tumblr {
//   background-color: #35465C;
//   border-color: #35465C;
// }

// .resp-sharing-button--tumblr:hover,
// .resp-sharing-button--tumblr:active {
//   background-color: #222d3c;
//   border-color: #222d3c;
// }

// .resp-sharing-button--email {
//   background-color: #777777;
//   border-color: #777777;
// }

// .resp-sharing-button--email:hover,
// .resp-sharing-button--email:active {
//   background-color: #5e5e5e;
//   border-color: #5e5e5e;
// }

// .resp-sharing-button--pinterest {
//   background-color: #bd081c;
//   border-color: #bd081c;
// }

// .resp-sharing-button--pinterest:hover,
// .resp-sharing-button--pinterest:active {
//   background-color: #8c0615;
//   border-color: #8c0615;
// }
