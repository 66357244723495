@import "../theme";
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: "GlacialIndifference-Bold";
  font-size: 16px;
  color: #4f4f4f;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 20px;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-container .checkmark {
  position: absolute;
  top: 50%;
  left: 12%;
  transform: translate(-50%, -50%);
  height: 24px;
  width: 24px;
  background: #f2f2f2;
  border: 1px solid #bdbdbd;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #f2f2f2;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
  top: 50%;
  left: 50%;
  height: 14px;
  background: #4c3f76;
  width: 14px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

/* Style the indicator (dot/circle) */
.radio-radio-container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
