@import "../theme";

ul {
  list-style: none;
}

:focus {
  outline: none;
}

.pointer {
  cursor: pointer;
}

.under-progress {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $brand-secondary;
  font-family: "GlacialIndifference";
  font-size: 30px;
}

.purple-small-text {
  font-family: "GlacialIndifference-Bold";
  font-size: 16px;
  color: #4c3f76;
}

.very-small-text {
  font-family: "Barlow";
  font-size: 14px;
  color: #232323;
}

.no-sroll {
  overflow: hidden;
  height: 100%;
}

.loader-container {
  height: 50vh;
}

.hide-desktop {
  display: none;
}

.hide-mobile {
  display: "";
}

.table thead th {
  vertical-align: top;
}

.text-purple {
  color: $brand-secondary;
  font-family: "GlacialIndifference-bold";
}

.download-btn {
  background-color: #ED1C24 !important;
  color: #fff !important;

  // border-radius: 30px !important;
  // padding: 10px 15px !important;
}

@media only screen and (max-width: 900px) {
  .hide-mobile {
    display: none !important;
  }

  .hide-desktop {
    display: block;
  }
}