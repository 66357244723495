$brand-primary: #ED1C24;
$brand-secondary: #ED1C24;
$brand-yellow: #ffd066;
$brand-green: #9febd0;
$tertiary-blue: #ade9f4;
$brand-pink: #f77a74;

$dark-text: #232323;
$lighter-text: #4f4f4f;
$line: #c0c0c0;
$light-bg: #f2f2f2;
$brand-primary-line: #bdbdbd;
$white: #ffffff;
$black: #000;
