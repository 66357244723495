.add-car-btn {
  width: 150px;
  height: 50px;
  right: 0;
  position: absolute;
}

.rc-doc-container {
  position: relative;

  .mandatory {
    position: absolute !important;
    bottom: -20px;
    left: 0;
    color: $brand-pink;
    font-weight: bold !important;
  }
}

.MuiFormControl-marginNormal {
  margin: 0 !important;
}