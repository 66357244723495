@import "../theme";

.notification{
  font-family: "GlacialIndifference-Bold";
  height: 48px;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
  position: fixed;
  top: 85px;
  width: 100vw;
  z-index: 1;
  left: 0;
}
.error {
  background: #f77a74;
  color: #fff;
}
.success {
  background: #ffd066;
  color: #4c3f76;
}
.info {
  background: #F2F2F2;
  color: #4c3f76;
}


@media only screen and (max-width: 1200px) {
  .notification{
    top: 61px !important;
    width: 100%;
    z-index: 1;
    margin-left: 0px;
  }
}
/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
