@import "../theme.scss";

.layout--tabs {
    box-shadow: none !important;

    span {
        font-family: "GlacialIndifference-bold";
        text-transform: none;
        font-size: 18px;
    }

    .MuiTabs-indicator {
        height: 5px;
        background-color: $brand-primary;
    }

    .Mui-selected span {
        color: $brand-primary;
    }
}