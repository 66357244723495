@import url("https://fonts.googleapis.com/css?family=Barlow:400,700&display=swap");

@font-face {
  font-family: GlacialIndifference-Bold;
  src: url("assets/fonts/GlacialIndifference/GlacialIndifference-Bold.woff2") format("woff2");
}

@font-face {
  font-family: GlacialIndifference;
  src: url("assets/fonts/GlacialIndifference/GlacialIndifference-Regular.woff2") format("woff2");
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c3cacf;
  border-radius: 5px;
  width: 5px;
}

body {
  margin: 0;
  font-family: "GlacialIndifference", "Barlow",
    "GlacialIndifference-Bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //min-height: 100vh;
}

html,
body,
#root,
#root>div {
  height: 100%
}