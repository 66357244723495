@import "../theme.scss";

.reduce-price-modal {
  .modal-header {
    background: $brand-primary;
    font-family: "GlacialIndifference-bold";
    font-size: 24px;
    padding-left: 25px;
    color: $white;
  }
  .close {
    font-size: 24px;
    color: $white;
    opacity: 1;
    font-weight: 100;
  }
  .title {
    font-family: "Barlow";
    font-size: 18px;
    color: $lighter-text;
    margin-bottom: 20px;
    display: inline-flex;
  }
  .loader-svg {
    margin-left: 10px;
  }
  .modal-body {
    padding: 0px;
  }
  .title-huge {
    font-family: "GlacialIndifference-bold";
    font-size: 36px;
    color: $dark-text;
  }
  input {
    max-width: 369px;
    padding: 10px;
    border: 1px solid $line;
    border-radius: 4px;
  }
  .price-container {
    margin-top: 50px;
    .error-message {
      padding-left: 40px;
      color: $brand-pink;
    }
  }
  .otp-container {
    margin-left: 40px;
    svg path {
      fill: $brand-green;
    }
  }
  .sendotp-btn {
    font-size: 18px;
    text-align: center;
    padding: 15px 12px;
    font-family: "GlacialIndifference";
    font-weight: bold;
    color: $line;
    display: flex;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: $brand-primary;
    }
  }
  .otpcode-list {
    max-width: 233px;
    input {
      margin-right: 5px;
      text-align: center;
      width: 24%;
      padding: 0;
      border: 1px solid $line;
      border-radius: 4px;
    }
  }
  .rupee {
    margin-right: 20px;
  }
  .note {
    font-family: "Barlow";
    font-size: 14px;
    color: $dark-text;
    padding: 20px 0px;
  }
  .modal-footer {
    align-items: flex-start;
    flex-direction: column;
    margin: 30px;
    padding: 0;
    h6 {
      font-family: "GlacialIndifference-bold";
      font-size: 24px;
      color: $brand-primary;
      padding: 30px 0px;
    }
  }
  .btn-cancel {
    margin: 0;
    padding: 0;
    margin-right: 30px;
  }
  .error-message {
    color: $brand-pink;
  }
  .content {
    max-height: 68vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 30px;
  }

  @media only screen and (max-width: 900px) {
    .title-huge {
      font-size: 32px;
    }
    .modal-footer {
      margin-top: 0px;
      margin-bottom: 15px;
    }
    .modal-footer h6 {
      font-size: 20px;
      padding: 10px 0px;
    }
    .content {
      padding: 0px;
    }
    .price-container {
      margin-top: 33px;
    }
    .note {
      margin-bottom: 0px;
      padding: 0px;
    }
    .sendotp-btn {
      width: 84px;
      font-size: 15px;
      padding-right: 0;
      padding-left: 0;
    }
    .btn{
      font-size: 15px;
    }
    h3.text-purple{
      font-size: 20px;
    }
  }
}
