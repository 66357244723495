@import "../theme.scss";

.car--details {
    .title {
        font-family: "Barlow";
        font-size: 14px;
        line-height: 17px;
        color: $black;
    }

    .description {
        font-family: "GlacialIndifference-bold";
        font-size: 24px;
        color: $dark-text;
    }
}