@import "../theme.scss";

.layout--login {
  overflow: hidden;
  height: 100%;
  background-color: #fff !important;

  .copyright-text {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-family: "Barlow";
    font-size: 14px;
    color: #323232;
    display: inline-flex;
    float: right;
    margin-right: 10px;
  }

  .layout--login--form {
    background: #d8d8d8;
    box-shadow: 0 4px 8px 0 #b8b8b8;
    border-radius: 5px;
    padding: 40px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  .layout-content {
    max-width: 1440px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 30px;
    width: 100%;
    height: 80vh;
    margin: 20px 0px;
  }



  .ad-image {
    max-height: 358px;
    max-width: 526px;
    width: 100%;
  }

  //registraion
  .left-section .header {
    font-family: "GlacialIndifference-Bold";
    font-size: 36px;
    color: #4f4f4f;
  }

  .left-section .registration-info {
    font-family: "Barlow";
    font-size: 14px;
    color: #4f4f4f;

    li {
      margin-bottom: 20px;
      margin-right: 30px;
    }
  }
}

.login-container .form-footer {
  font-family: "Barlow";
  font-size: 14px;
}

.login-container .form-footer a {
  font-family: "GlacialIndifference-Bold";
  font-size: 18px;
  color: #29c7e2;
  cursor: pointer;

  &:hover {
    color: #29c7e2;
  }
}

.layout--login--form {
  max-width: 90vw;

  form {
    width: 293px;
  }

  .description {
    font-family: "Barlow";
    font-size: 18px;
    color: #4f4f4f;
    font-weight: 500;
  }

  .input-otp {
    border: 2px solid transparent;
    cursor: pointer;
    text-align: center;
    font: 2.5ch consolas, monospace;
    color: #323232;
    background: #eeeeee;
    border-radius: 8px;
    width: 3.5ch;
    height: 40px;
    margin-right: 10px;
  }

  .input-otp:focus {
    border: 2px solid #323232;
  }

  .submit-button {
    width: 175px;
    height: 52px;
    font-size: 16px;
    margin-top: 45px !important;

    .loader-svg {
      margin-right: 10px;
    }
  }

  .title {
    font-family: "GlacialIndifference-Bold";
    font-size: 24px;
    text-align: left;
    color: #232323;
  }

  .input-group.invalid>#partitioned {
    background-image: linear-gradient(to left,
        #f77a74 70%,
        rgba(255, 255, 255, 0) 0%);
    //border-color: #f77a74;
  }

  .input-group.invalid>.input-otp {
    border: 1px solid #f77a74 !important;
  }

  .input-text {
    width: 300px;
    height: 44px;
    background: #f2f2f2;
    border-radius: 5px;
    border: 0px;
    padding: 0px 10px;
    font-size: 20px;
    color: #323232;
  }

  .error-message {
    font-family: "Barlow";
    font-size: 12px;
    color: #f77a74;
    margin-top: 14px;
  }
}

#partitioned {
  border: none;
  width: 15ch;
  cursor: text;
  background: repeating-linear-gradient(90deg,
      #4f4f4f 0,
      #4f4f4f 1ch,
      transparent 0,
      transparent 1.5ch) 0 100%/100% 2px no-repeat;
  color: #323232;
  padding: 0px;
  font: 3ch consolas, monospace;
  font-weight: 400;
  letter-spacing: 0.5ch;
}

#partitioned:focus {
  outline: none;
  color: #323232;
}

#divInner {
  left: 0;
  position: sticky;
}

#divOuter {
  width: 293px;
  overflow: hidden;
}

//------Media Queries------
@media only screen and (max-width: 786px) {
  .layout--login {
    overflow: auto;

    .logo {
      height: 40px;
      position: static !important;
    }
    
    .layout-content {
      padding-top: 4rem;
      flex-direction: column-reverse;
      height: 80vh !important;
      margin-top: -50px;
      // margin-top: 20px;


      .left-section {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        width: 100%;
      }

      .left-section {
        margin-top: 35px;
      }

      .ad-image {
        width: 80%;
      }

      .login-container {
        margin: 20px 0px;
      }

      .layout--login--form .submit-button {
        width: 168px;
        height: 48px;
      }
    }
  }

}